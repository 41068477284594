import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Table } from "../s25-table/Table";
import { RuleTreeService } from "../../services/rule.tree.service";
import { Bind } from "../../decorators/bind.decorator";
import { GenericTableListComponent } from "../s25-table/generics/generic.table.list.component";
import { GenericTableButtonComponent } from "../s25-table/generics/generic.table.button.component";
import { ModalService } from "../modal/modal.service";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { CacheRepository } from "../../decorators/cache.decorator";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Rules } from "./s25.rule.const";
import { S25RuleTreeUtil } from "./s25.rule.tree.util";
import { GenericTableFadePreviewComponent } from "../s25-table/generics/generic.table.fade.preview.component";
import { GenericTableRenderHTMLComponent } from "../s25-table/generics/generic.table.render.html.component";

@TypeManagerDecorator("s25-ng-rule-tree")
@Component({
    selector: "s25-ng-rule-tree",
    template: `
        <button class="aw-button aw-button--primary" (click)="onNewRuleClick()">Add New Root Rule</button>
        <s25-ng-table
            [dataSource]="tableConfig"
            [columnSortable]="true"
            [hasFilter]="true"
            [hasRefresh]="true"
        ></s25-ng-table>
    `,
    styles: `
        button {
            margin-bottom: 1em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RuleTreeComponent implements OnInit {
    @Input() category?: Rules.Category = "form";
    @Input() subCategory?: string;
    @Input() conditionFilterMap?: Rules.ConditionFilterMap;

    tableConfig: Table.DataSource;
    rulesById: Record<number, Rules.Rule> = {};

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    ngOnInit() {
        const columns: Table.Column[] = [
            { id: "name", header: "Rule Name" },
            { id: "active", header: "Active", width: 70 },
            { id: "actions", header: "Actions", minWidth: 210 },
            { id: "targets", header: "Targets", maxWidth: 300 },
            GenericTableButtonComponent.Column("Copy", this.onCopyClick, "outline"),
            GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
            GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
        ];

        this.tableConfig = {
            type: "unpaginated",
            columns: columns,
            dataSource: this.getAllRules,
        };
    }

    @Bind
    async getAllRules(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByStartsWith("RuleTreeService.getRules");
        const rawRules = await RuleTreeService.getRules(this.category, true);
        let rules = S25RuleTreeUtil.parseRules(rawRules);
        if (this.subCategory) {
            rules = rules.filter((r) => r.subCategory === this.subCategory);
        }
        for (let rule of rules) this.rulesById[rule.id] = rule;
        return { rows: rules.map(this.mapRuleToRow), totalRows: rules.length };
    }

    mapRuleToRow(rule: Rules.Rule): Table.Row {
        const actions = Object.entries(rule.targets)
            .map(([action, targets]) => ({
                action,
                targets,
                label: Rules.targetActionToTarget[action as Rules.Action]?.label ?? "",
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        const targetItems = Object.values(rule.targets)
            .flat()
            .map((target) => target.itemName ?? target.itemValue);

        // Each action will be rendered as a GenericTableListComponent containing the target items
        const items: Table.Cell[] = actions.map((a) => {
            const { action, targets, label } = a;
            let items: Table.Cell[] = [];
            if (action === "alertUser" || action === "notifyUser" || action === "confirm") {
                // Render Alert and Notify as fade preview HTML
                items = targets.map((target) => ({
                    component: GenericTableRenderHTMLComponent,
                    inputs: { isHtml: true, text: target.itemValue },
                }));
            } else if (action === "preventSave") {
                items = targets.map((target) => ({ text: S25Util.toBool(target.itemValue) ? "Yes" : "No" }));
            } else if (action === "minAdditionalTime") {
                // Split into four items with descriptions
                targets.map((target) => {
                    const [additionalTime, err] = S25Util.parseJson<Rules.AdditionalTimes>(target.itemValue);
                    if (err) return;
                    for (const type of ["setup", "pre", "post", "takedown"] as const) {
                        const time = additionalTime[type];
                        if (!time.days && !time.hours && !time.minutes) continue;
                        items.push({
                            text: `${S25Util.firstCharToUpper(type)}: ${S25Util.date.printDuration(time.days, time.hours, time.minutes)}`,
                        });
                    }
                    return items;
                });
            } else {
                items = targets.map((target) => ({ text: target.itemName ?? target.itemValue }));
            }
            return {
                text: label,
                component: GenericTableListComponent,
                inputs: { items, hasPagination: false },
            };
        });

        const labels = actions.map((a) => a.label);
        return {
            id: rule.id,
            name: rule.name,
            cells: {
                name: { text: rule.name },
                active: { text: rule.active ? "Yes" : "No" },
                actions: {
                    component: GenericTableListComponent,
                    inputs: {
                        items: labels.map((label) => ({ text: label })),
                        hasPagination: false,
                    },
                    textValue: labels.join(" "),
                },
                targets: {
                    component: GenericTableFadePreviewComponent,
                    inputs: {
                        maxHeight: 120,
                        cell: { component: GenericTableListComponent, inputs: { items, hasPagination: false } },
                    },
                    textValue: targetItems.join(" "),
                },
            },
        };
    }

    @Bind
    onCopyClick(row: Table.Row) {
        const rule = S25Util.deepCopy(this.rulesById[row.id as number]);
        rule.name += " copy";
        rule.id = -1 * Math.ceil((Math.random() + 1) * 100000000); // Just need an ID that is not taken yet?
        rule.conditionFilterMap = this.conditionFilterMap;
        ModalService.modal("edit-rule", {
            rule,
            title: `Copy Rule: ${row.name}`,
            isNew: true,
            refresh: this.tableComponent.refresh,
        });
    }

    @Bind
    onEditClick(row: Table.Row) {
        const rule = S25Util.deepCopy(this.rulesById[row.id as number]);
        rule.conditionFilterMap = this.conditionFilterMap;
        ModalService.modal("edit-rule", {
            rule,
            title: `Edit Rule: ${rule.name}`,
            refresh: this.tableComponent.refresh,
        });
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this rule?",
                title: "Deletion Confirmation",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no
        await RuleTreeService.delRule(row.id).catch(S25Util.showError);
        this.tableComponent.refresh();
    }

    onNewRuleClick() {
        const newRule: Rules.Rule = {
            id: -1 * Math.ceil((Math.random() + 1) * 100000000), // Just need an ID that is not taken yet?
            name: "New Rule",
            active: true,
            category: this.category,
            subCategory: this.subCategory,
            targets: {
                form: {
                    addCustAtrb: [],
                },
                formValidation: {
                    addCustAtrb: [],
                },
                match: {
                    matchGroup: [{ itemValue: "", itemName: "", itemId: -1, itemTypeId: -1 }],
                },
                matchForm: {
                    hideQuestion: [],
                    showQuestion: [],
                },
            }[this.category],
            conditions: {
                operator: "and",
                children: [],
            },
            conditionFilterMap: this.conditionFilterMap,
        };
        ModalService.modal("edit-rule", {
            rule: newRule,
            title: "Create New Rule",
            isNew: true,
            refresh: this.tableComponent.refresh,
        });
    }
}
