import { NgModule } from "@angular/core";
import { S25RuleTreeComponent } from "./s25.rule.tree.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { CommonModule } from "@angular/common";
import { S25RuleComponent } from "./s25.rule.component";
import { FormsModule } from "@angular/forms";
import { S25RuleConditionComponent } from "./s25.rule.condition.component";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25EditableBooleanModule } from "../s25-editable/s25-editable-boolean/s25.editable.boolean.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25TimepickerModule } from "../s25-timepicker/s25.timepicker.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25RuleConditionsComponent } from "./s25.rule.conditions.component";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { S25TriggerRerenderModule } from "../s25-trigger-rerender/s25.trigger.rerender.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25RuleTargetComponent } from "./s25.rule.target.component";

@NgModule({
    declarations: [S25RuleTreeComponent, S25RuleComponent, S25RuleConditionComponent, S25RuleConditionsComponent],
    imports: [
        S25TableModule,
        CommonModule,
        FormsModule,
        S25DropdownPaginatedModule,
        S25EditableBooleanModule,
        S25EditableModule,
        S25TimepickerModule,
        S25MultiselectModule,
        S25SimpleCollapseModule,
        S25TriggerRerenderModule,
        S25IconModule,
        S25CheckboxModule,
        S25ToggleButtonModule,
        S25RichTextEditorModule,
        S25RuleTargetComponent,
    ],
    exports: [S25RuleTreeComponent, S25RuleComponent],
    providers: [S25RuleTreeComponent, S25RuleComponent, S25RuleConditionComponent, S25RuleConditionsComponent],
})
export class S25RuleTreeModule {
    constructor() {}
}
